<template>
  <div class="blog">
    <main-nav></main-nav>
    <div class="my-page my-page-top-padded theme-font blog-text-very-dark-gray">

      <!-- Title -->
      <div class="blog-title">
        Blog
      </div>
      <div class="blog-subtitle">
        The World of Digital Gifting
      </div>

      <div class="blog-content-container">

        <!-- Separator -->
        <hr class="blog-hr" />

        <!-- List -->
        <div class="blog-items">
          <template v-for="(blog, i) in blogs">

            <router-link :to="blog.path" :key="i" class="blog-item-link blog-text-very-dark-gray">
              <div class="blog-item">

                <!-- Image -->
                <div class="blog-item-image-container">
                  <img :src="blog.image" :alt="blog.title" class="blog-item-image">
                </div>

                <!-- Title -->
                <div class="blog-item-title">
                  {{blog.title}}
                </div>

                <!-- Subtitle -->
                <div class="blog-item-subtitle blog-text-gray">
                  {{blog.shortDescription}}
                </div>

              </div>
            </router-link>

          </template>

        </div>

        <template v-if="!blogs.length">
          <div class="text-center" style="font-size:22px; margin:auto; max-width:300px;">
            We have some exciting content coming soon!
          </div>
        </template>

      </div>

      <blog-footer></blog-footer>
    </div>
  </div>
</template>

<script>
  import blogs from '@/views/blogs';
  import MainNav from '@/components/navs/MainNav.vue';
  import BlogFooter from '@/components/blog/BlogFooter.vue';
  export default {
    name:'Blog',
    components: {
      MainNav,
      BlogFooter,
    },
    data() {
      return {
        blogs,
      }
    },
    mounted() {
      // debug
      // console.log('blogs:');
      // console.log(blogs);
    },
  };
</script>

<style scoped>

  /* .blog {
    padding-top: 72px;
  } */

  .blog-content-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1220px
  }

  .blog-title {
    font-size: 64px;
    text-align: center;
  }
  .blog-subtitle {
    padding-left: 24px;
    padding-right: 24px;
    font-size: 32px;
    text-align: center;
  }
  @media only screen and (max-width: 700px) {
    .blog-title {
      font-size: 42px;
    }
    .blog-subtitle {
      font-size: 24px;
    }
  }

  .blog-items {
    /* padding: 24px; */
    padding-left: 24px;
    padding-top: 24px;
  }

  .blog-item-link {
    text-decoration: none;
  }
  .blog-item {
    display: inline-block;
    vertical-align: top;
    margin-right: 24px;
    margin-bottom: 24px;
    max-width: 360px;
  }
  .blog-item-image {
    max-height: 280px;
  }
  .blog-item-title {
    font-size: 26px;
  }
  .blog-item-subtitle {
    font-size: 18px;
  }
  @media only screen and (max-width: 900px) {
    .blog-item {
      max-width: 300px;
    }
    .blog-item-image {
      max-height: 220px;
    }
    .blog-item-title {
      font-size: 22px;
    }
    .blog-item-subtitle {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 680px) {
    .blog-item {
      max-width: 999999px;
    }
    .blog-item-image {
      max-height: 500px;
      max-width: 100%;
    }
    .blog-item-title {
      font-size: 22px;
    }
    .blog-item-subtitle {
      font-size: 16px;
    }
  }

</style>
